import React from 'react'
import Workprocess from '../component/workprocess/Workprocess';




export const Blogs = () => {
  return (
    <div>
      <Workprocess/>
    </div>
  )
}
export default Blogs;