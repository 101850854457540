import React from 'react'
import Aboutpage from "../component/about/Aboutpage"


export const About = () => {
  return (
    <div>
      <Aboutpage/>
    </div>
  )
}
export default About;