import React from 'react'
import "./Workprocess.css"
import workone from "../assert/work-one.jpg"
import worktwo from "../assert/work-two.jpg"
import workthree from "../assert/work-three.webp"
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();


export const Workprocess = () => {
  return (
<div className="work-overall">
      <div
       data-aos="fade-right"
       data-aos-offset="200"
       data-aos-duration="1000"
       data-aos-once="false"
      className="work-procss-container">
        <h1>WORK PROCESS</h1>
        <p>How it Works </p>

    <div className="three-types">

        <div
        data-aos="zoom-in-right"
         data-aos-offset="300"
         data-aos-duration="1000"
         data-aos-once="false"
        className="work-small">
        <img src={workone} alt="" />
        <p>Call us</p>
        </div>


        <div 
         data-aos="flip-left"
         data-aos-offset="300"
         data-aos-duration="1000"
         data-aos-once="false"
        className="work-big">
        <img src={worktwo} alt="" />
       <p>Our Expert Will collect</p>
        </div>



        <div
        data-aos="zoom-in-right"
        data-aos-offset="300"
        data-aos-duration="1000"
        data-aos-once="false"
        className="work-small">
        <img src={workthree} alt="" />
        <p>Relay & Enjoy</p>
        </div>
       
   
    
    </div>


    </div>
</div>
  )
}
export default Workprocess;