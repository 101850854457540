import React from 'react'
import Carousel from '../component/carousel/Carousel';
import { Ewast } from '../component/ewast/Ewast';
import { Aboutpage } from '../component/about/Aboutpage';
import {Servicepage} from "../component/servicepage/Servicepage"
import Popular from '../component/popular/Popular';
import Workprocess from '../component/workprocess/Workprocess'
import Contactform from '../component/contactform/Contactform';
import Emptydespin from '../component/emptydespin/Emptydespin'
import Experience from '../component/experience/Experience';


export const Home = () => {
  return (
    <div>
      <Carousel/>
      <Ewast/>
      <Aboutpage/>
      <Servicepage/>
      <Popular/>
      <Experience/>
      <Workprocess/>
      <Contactform/>
      <Emptydespin/>
     
    </div>
  )
}
export default Home;