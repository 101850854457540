import React from 'react'
import "./Emptydespin.css"
import imgfooter from '../assert/footer-image.png'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

export const Emptydespin = () => {
  return (
  <div className="map-container">


   <div className="emptydespin-con">

       <div
       data-aos="zoom-in"
       data-aos-offset="200"
       data-aos-duration="1000"
       data-aos-once="false"
       className="iframe-detials-left">
     

       <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3916.861568453907!2d77.01534537504476!3d10.973819889187176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDU4JzI1LjgiTiA3N8KwMDEnMDQuNSJF!5e0!3m2!1sen!2sin!4v1709544766542!5m2!1sen!2sin"  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
       </div>

      <div 
      data-aos="fade-left"
      data-aos-offset="200"
      data-aos-duration="1000"
      data-aos-once="false"
      className="despin-boy-img-right">
      <img src={imgfooter} className='emptyfooder-img' />
      </div>
    


   </div> 
    </div>
  )
}
export default Emptydespin;