import React, { useState } from 'react'
import "./Contactform.css"
import { IoLocation } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import {Link} from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();


export const Contactform = () => {
  const [name,setname]=useState()
  const [email,setemail]=useState()
  const [service,setsertvice]=useState()
  const [mobnum,setmobnum]=useState()
  const [message,setmessage]=useState()

  return (
<div className="contact-form-container">
<div className="contact-form-detials">
    <h1 
    data-aos="zoom-in"
    data-aos-offset="200"
    data-aos-duration="1000"
    data-aos-once="false"
    className='contactus-title'>Contact Us</h1>
    <p 
    data-aos="zoom-in"
    data-aos-offset="200"
    data-aos-duration="1000"
    data-aos-once="false"
    className='touch-para'>Get In Touch</p>

<div
data-aos="fade-right"
data-aos-offset="300"
data-aos-duration="1000"
data-aos-once="false"
className="contact-form-left">{/* contact-form-left */}
<div className="left-center">{/* flex */}

<div className="con-center">{/*  overall-container */}
   <p className='forany'>For Any Kind of Help.</p>

   <div className="icon-detials">
    <IoLocation className='con-icons' />
        <div className="contact-detial">
            <p className='con-title'>Address</p>
            <p className='con-detial'>Elgi Nagar Vellalur,Coimbatore.641 111</p>
        </div>
   </div>

   <div className="icon-detials">
    <FaPhoneAlt className='con-icons' />
        <div className="contact-detial">
                <p className='con-title'>Call For Help</p>
                <p className='con-detial'>+91-8681991212</p>
        </div>
   </div>

   <div className="icon-detials">
    <MdEmail className='con-icons' />
    <div className="contact-detial">
                <p className='con-title'>Mail us for Information</p>
                <p className='con-detial'>bestscrapscbe@gmail.com</p>
        </div>
   </div>
   </div>
</div>
</div>












  <div
  data-aos="fade-left"
  data-aos-offset="300"
  data-aos-duration="1000"
  data-aos-once="false"
  className="contact-form-right">{/* contact-form-right */}


  <div className="forms-con">

    <div className="name-service">

        <div className="form-inp">
        <input type="text"  placeholder='Name'
        value={name}
        onChange={(e)=>{setname(e.target.value)}}
        />
        
        <input type="text" placeholder='Service'
         value={service}
         onChange={(e)=>{setsertvice(e.target.value)}}
         /></div>

    </div>


    <div className="email-phone">   

      <div className="form-inp">
        <input type="text" placeholder='Email'
        value={email}
        onChange={(e)=>{setemail(e.target.value)}}
        />
        <input type="text" placeholder='Phone' 
        value={mobnum}
        onChange={(e)=>{setmobnum(e.target.value)}}
        /></div>

    </div>
    <textarea placeholder='Message' className='messg'
    value={message}
    onChange={(e)=>{setmessage(e.target.value)}}
    ></textarea>
   <div className="con-btns">


     <Link to={"https://wa.me/+918681991212?text="+`Name:${name}`+"%0a"+
     `email:${email}`+"%0a"+
     `service:${service}`+"%0a"+
     `mobile number:${mobnum}`+"%0a"+
     `message:${message}`+"%0a"
     }><button>Submit</button></Link>
   
   </div>
  </div>{/* forms */}


  </div>


</div>
</div>
  )
}
export default Contactform;