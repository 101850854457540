import React, { useState } from 'react'
import "./Nav.css"
import logo from '../assert/logo.png'
import { Link } from 'react-router-dom'
import { IoMdClose } from "react-icons/io";
import { FaBars } from "react-icons/fa";

export const Nav = () => {
    const [responsible,setresponsible]=useState(false);
    const [active,setactive]=useState("home")
  return (
    <nav className='nav-bar'>

     <div className='log-icons'>
            <img src={logo} alt="company-logo"  className='company-logo'/>
           <button onClick={()=>{setresponsible(!responsible)}} className='hidden-btn'>{responsible ?<IoMdClose className='x-icon' />:<FaBars />} </button>
     </div>
     
     <ul className={responsible?"ultag-view":"ultag"} onClick={()=>{setresponsible(false)}} >
 
        <Link to="/" onClick={()=>{setactive("home")}}><li className={active==="home"?"nav-active":""}>Home</li></Link>
        <Link to="/service"onClick={()=>{setactive("service")}}><li className={active==="service"?"nav-active":""}>Service</li></Link>
        <Link to="/blogs"onClick={()=>{setactive("blogs")}}><li className={active==="blogs"?"nav-active":""}>Blogs</li></Link>
        <Link to="/about"onClick={()=>{setactive("aboutus")}}><li className={active==="aboutus"?"nav-active":""}>About Us</li></Link>


     <Link to="/reqvest"><button className='reqvest-btn'>Request for Pickup</button></Link>


    <div className="contact-ph-email">
         <Link to="tel:+918681991212"><p className='nav-phone-number'>+91-8681991212</p></Link>
         <Link to="mailto:bestscrapscbe@gmail.com"><p className='nav-email'>bestscrapscbe@gmail.com</p></Link>
    </div>


    </ul>

    </nav>
  )
}
export default Nav;