import React from 'react'
import "../about/Aboutpage.css"
import auto from "../assert/ewast-auto.png"
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

export const Aboutpage = () => {
  return (
<div className="aboupage-container">
  <div className="aboupage-container-overall">



            <div 
             data-aos="fade-right"
             data-aos-offset="100"
             data-aos-duration="1000"
             data-aos-once="false"
            className="aboupage-right">
                <h1 className='aboutus-head'>ABOUT US</h1>
                <p className='aboutus-para-one'>We are leading scrap buyer in Coimbatore.</p>
                <p className='about-long-para'>At BestScraps, we are proud to have been providing top-notch recycling services since 2023. Our commitment to environmental sustainability and eco-friendly practices has made us a leading company in the recycling industry and a top scrap buyer in Coimbatore. Our focus on providing high-quality and efficient services has earned us a reputation for excellence in the industry. Our team of technically qualified employees and our own fleet of vehicles allow us to provide efficient pick-up services for all types of scrap materials in Coimbatore and the surrounding areas.</p>
              <div className="contact-btn">
               
               <Link to="/contactone"><button onClick={()=>{window.scrollTo({top:0,left:0})}}>Contact Us</button></Link>
              </div>
            </div>
          



            <div
             data-aos="fade-left"
             data-aos-offset="100"
             data-aos-duration="1000"
             data-aos-once="false"
            className="aboupage-left">
              <img src={auto} alt="auto" className='ewast-auto-img' />
            </div>
  </div>
</div>
  )
}
export default Aboutpage;