import React from 'react'
import Reqvestpickup from "../component/reqvestpage/Reqvestpickup"

export const Reqvest = () => {
  return (
    <div>
      <Reqvestpickup/>
    </div>
  )
}
export default Reqvest;