import React from 'react'
import "../ewast/Ewast.css"
import ewastimg  from '../assert/e-wast-img.png'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();
export const Ewast = () => {
  


  return (
<div className="e-wast-container">
<div className="overall-container-ewast">

       <div
       data-aos="fade-right"
       data-aos-offset="100"
       data-aos-duration="1000"
       data-aos-once="false"
       className="e-wast-left">
                <p className='prefect-para'>Perfect Solution For Your <span>E-WASTE</span></p>
             <div className="e-wast-para-con">
             <p className='e-wast-para'>E-Waste Management & Recycle Company of Coimbatore offers a wide range of electronic recycling</p>
             </div>
                <div className="ewast-btn">
                  <Link to="/contactone" ><button  onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>Contact Us</button></Link>
                   
                </div>
        </div>



        <div 
         data-aos="fade-left"
         data-aos-offset="100"
         data-aos-duration="1000"
         data-aos-once="false"
        className="ewast-right">
          
         <img src={ewastimg} alt="e-wast-img"  />
        </div>
  </div>
  </div>
  )
}
export default Ewast;