import React from 'react'
import "./Popular.css";
import imgone from "../assert/Computer-Scrap-icon.png"
import imgtwo from "../assert/E-Waste-Recycling-icon.png"
import imgthree from "../assert/Battery-Scrap-icon.png"
import imgfour from "../assert/Electrical-and-Electronic-Scrap-icon.png"
import imgfive from "../assert/Metal-Scrap-icon.webp"
import imgsix from "../assert/AC-Scrap-icon.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();
 
export const Popular = () => {
  return (
<div className="main-container-poplur">
<div className="con-pop">
             <h1
              data-aos="zoom-in-up"
              data-aos-offset="200"
              data-aos-duration="1000"
              data-aos-once="false"
             >WHAT WE ME</h1>
             <p 
             data-aos="zoom-in-up"
             data-aos-offset="200"
             data-aos-duration="1000"
             data-aos-once="false"
             className='our-most'>Our Most Popular Services For You</p>
             
<div className="pop-detial-container">

      <div
       data-aos="flip-left"
       data-aos-offset="100"
       data-aos-duration="1000"
       data-aos-once="false"
        className="pop-detial">
            <div className="pop-img">
                <img src={imgone} alt="imgone" />
            </div>
            <p className='pop-title'>Computer scrap</p>

            <div className="pop-para-con">
                    <p className='pop-para'>we are specializes in purchasing and recycling         used or surplus computer equipment. We purchase a wide range of         computer-related scrap, including.</p>
            </div>
           
      </div>



      <div
       data-aos="flip-right"
       data-aos-offset="100"
       data-aos-duration="1000"
       data-aos-once="false"
        className="pop-detial">
            <div className="pop-img">
                <img src={imgtwo} alt="imgtwo" />
            </div>
            <p className='pop-title'>E- Waste scrap</p>
           <div className="pop-para-con">
            <p className='pop-para'>we offer professional e-waste recycling services to help you safely and responsibly dispose of your electronic waste. E-waste, or electronic waste</p>
            </div>
</div>


      <div 
      data-aos="flip-left"
       data-aos-offset="100"
       data-aos-duration="1000"
       data-aos-once="false"
       className="pop-detial">
            <div className="pop-img">
                <img src={imgthree} alt="imgthree" />
            </div>
            <p className='pop-title'>Battery scrap</p>
            <div className="pop-para-con">
            <p className='pop-para'>we are proud to offer top-notch battery scrap buying services to our customers. As a leading e-waste recycling company, we specialize in the collection, processing</p>
            </div>

     </div>



      <div
       data-aos="flip-right"
       data-aos-offset="100"
       data-aos-duration="1000"
       data-aos-once="false"
        className="pop-detial">
            <div className="pop-img">
                <img src={imgfour} alt="imgfour" />
            </div>
            <p className='pop-title'>Electrical scrap</p>
           <div className="pop-para-con">
            <p className='pop-para'>we specialize in the purchase of electrical and electronic scrap. As a leading provider of e-waste recycling services, we are committed to helping businesses</p>
            </div>

     </div>




      <div
       data-aos="flip-right"
       data-aos-offset="100"
       data-aos-duration="1000"
       data-aos-once="false"
        className="pop-detial">
            <div className="pop-img">
                <img src={imgfive} alt="imgfive" />
            </div>
            <p className='pop-title'>Metal  scrap</p>
           <div className="pop-para-con">
            <p className='pop-para'>we specialize in buying all types of metal scrap, including copper, aluminum, brass, stainless steel, and more. Our team of experienced professionals is dedicated</p>
            </div>
    </div>

      <div
       data-aos="flip-left"
       data-aos-offset="100"
       data-aos-duration="1000"
       data-aos-once="false"
        className="pop-detial">
            <div className="pop-img">
                <img src={imgsix} alt="imgsix" />
            </div>
            <p className='pop-title'>AC  scrap</p>
           <div className="pop-para-con">
            <p className='pop-para'>we specialize in buying scrap AC units. As a leading scrap AC buyer, we are committed to providing our customers with top dollar for their scrap AC units</p>
            </div>

       </div>








</div>



</div>
</div>
  )
}
export default Popular;