import React from 'react'
import "./Servicepage.css"
import electronicwast from '../assert/service-img.png'
 


export const Servicepage = () => {
  return (
    
<div className="service-all-contant-con">
<div className="services-container">


    <div 
    data-aos="zoom-in"
    data-aos-offset="200"
    data-aos-duration="1000"
    data-aos-once="false"
    className="service-left">
              <h1>SERVICES</h1>
          <div className="expert">
          <p>We expertise in E-waste management to provide the world with economic,envinormental, and social benefits.</p>
          </div>
          
            
    <div className="container-for-left-right">
              <div className="serve-left-contant gap-con">
 
                <p> <span className='tick'>☑</span> Computer scrap</p>
                <p> <span className='tick'>☑</span>Metal scrap</p>
                <p> <span className='tick'>☑</span>Industrial scrap</p>
                <p> <span className='tick'>☑</span>Office scrap</p>
          
                </div>

                <div className="serve-right-contant gap-con">
             
                <p> <span className='tick'>☑</span>Battery scrap</p>
                <p> <span className='tick'>☑</span>AC scrap</p>
                <p> <span className='tick'>☑</span>Book & paper scrap</p>
                <p> <span className='tick'>☑</span>Electric & electronic scrap</p>
                
           
                </div>

    </div>


  </div>








    <div className="service-right">
   
     <div
     data-aos="zoom-in-up"
     data-aos-offset="200"
     data-aos-duration="1000"
     data-aos-once="false"
     className="serve-two-img">
          <img src={electronicwast} alt="electronicwast" className='electronicwast' />
          
      </div>
    
    </div>


</div>
</div>
  )
}
export default Servicepage;