import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Nav from './component/navbar/Nav';
import Home from "../src/pages/Home";
import Service from "../src/pages/Service"
import Blogs from "../src/pages/Blogs"
import About from '../src/pages/About'
import Reqvest from "../src/pages/Reqvest"
import Fooderpage from "../src/component/fooderpage/Fooderpage" 
import Copyright from './component/copyright/Copyright';
import Contactform from './component/contactform/Contactform';
 


function App() {
  return (
    <div className="App">
    
      <Nav/>
   
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/service' element={<Service/>}/>
        <Route path='/blogs' element={<Blogs/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/reqvest' element={<Reqvest/>}/>  
        <Route path='/reqvestpages' element={<Reqvest/>}/>  
        <Route path="/contactone" element={<Contactform/>} />  
      </Routes>


      
 
       <Fooderpage/>
       <Copyright/>
      
    </div>
  );  
}

export default App;
