import React, { useState } from 'react'
import "./Reqvestpickup.css"
import {Link} from 'react-router-dom'
export const Reqvestpickup = () => {
 const [firstname,setfirstname]=useState()
 const [lastname,setlastname]=useState()
 const [addresone,setaddresone]=useState()
 const [addresstwo,setaddresstwo]=useState()
 const [landmark,setlandmark]=useState()
 const [pincode,setpincode]=useState()
 const [state,setstate]=useState()
 const [phonenum,setphonenum]=useState()
 
 

  return (
<div className="reqvest-pickup-con">
<div className="reqvest-pickup">
        <h1>REQUEST FOR PICKUP</h1>
        <p>Contact Details</p>
     <div
     className='pic-up-forms'>

         <div className="picup-input"> 

              <div className="picup-right">
                <div className="pic-flex">
               <label htmlFor="">First Name</label>
               <input  type="text" placeholder='First Name'
               value={firstname}
               onChange={(e)=>{setfirstname(e.target.value)}}
               id='fnamess'/>
              </div></div>

              <div className="picup-left">
              <div className="pic-flex">
                <label htmlFor="">Last Name</label>
               <input type="text" placeholder='Last Name' 
                value={lastname}
                onChange={(e)=>{setlastname(e.target.value)}}
               /></div>
              </div>
            
         </div>




         <div className="picup-input">
               <div className="picup-right">
               <div className="pic-flex">
               <label htmlFor="">Address Line 1</label>
               <input type="text" placeholder='Address Line 1'
               value={addresone}
               onChange={(e)=>{setaddresone(e.target.value)}}
               />
              </div></div>

              <div className="picup-left">
              <div className="pic-flex">
                <label htmlFor="">Address Line 2</label>
               <input type="text" placeholder='Address Line 2' 
                value={addresstwo}
                onChange={(e)=>{setaddresstwo(e.target.value)}}
               />
              </div></div>
        </div>




        <div className="picup-input">
              <div className="picup-right">
              <div className="pic-flex">
                  <label htmlFor="">Land Mark</label>
                  <input type="text" placeholder='Land Mark'
                  value={landmark}
                  onChange={(e)=>{setlandmark(e.target.value)}}
                  />
              </div></div>

              <div className="picup-left">
              <div className="pic-flex">
                    <label htmlFor="">Pincode</label>
                     <input type="text" placeholder='Pincode'
                     value={pincode}
                     onChange={(e)=>{setpincode(e.target.value)}}
                     />
              </div></div>
        </div>

        <div className="picup-input">

              <div className="picup-right">
              <div className="pic-flex">
                  <label htmlFor="">State</label>
                  <input type="text" placeholder='Tamil Nadu' 
                  value={state}
                  onChange={(e)=>{setstate(e.target.value)}}
                  />
              </div></div>

              <div className="picup-left">
              <div className="pic-flex">
                    <label htmlFor="">Mobile no</label>
                  <input type="text" placeholder='Ex:+918681991212' 
                  value={phonenum}
                  onChange={(e)=>{setphonenum(e.target.value)}}
                  />
              </div></div>


        </div>
  
     <div className="pick-btns">
      <Link to={"https://wa.me/+918681991212?text="+`First Name:${firstname}`+"%0a"+
     `Last Name:${lastname}`+"%0a"+
     `Address Line 1:${addresone}`+"%0a"+
     `Address Line 2:${addresstwo}`+"%0a"+
     `Land Mark:${landmark}+%0a`+
     `Pincode:${pincode}`+"%0a"+
     `State:${state}+%0a`+
     `Mobile Number:${phonenum}`
     }> <button >Submit</button> </Link>
     </div>


     </div>





   







</div>
</div>
  )
}
export default Reqvestpickup;