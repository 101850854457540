import React from 'react'
import "./Fooderpage.css"
import { MdContactPhone } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import logo from '../assert/logo.png'
import { FaInstagram } from "react-icons/fa";
import { AiOutlineFacebook } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io5";
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();






export const Fooderpage = () => {
  return (
<div className="fooder-overall-container">
<div className="fooder-container">

    <div
   data-aos="fade-right"
    data-aos-offset="100"
    data-aos-duration="1000"
    data-aos-once="false"
     className="opening-hours">{/* opening-hours */}
         <p className='fooder-titles'>OPENING HOURS</p>
         <p className='open-time'>6.00 AM - 9.00AM</p>
         <div className="icon-fooder-num">
          <MdContactPhone className='mobile-num-ic' />
          <Link to="tel:91878201109"><p>+91-8681991212</p></Link>
         </div>
         <div className="icon-fooder-email">
            <MdEmail  className='mobile-num-ic'/>
            <Link to="mailto:bestscrapscbe@gmail.com"><p >bestscrapscbe@gmail.com</p></Link>
         </div>
    </div>


    <div
    data-aos="fade-right"
    data-aos-offset="100"
    data-aos-duration="1000"
    data-aos-once="false"
     className="quick-link">{/* quick-link */}
       <p className='fooder-titles'>Quick Link</p>
       <ul className='quick-ultage'>
        <li>Home</li>
        <li>About Us</li>
        <li>Services</li>
        <li>Contact Us</li>
       </ul>
    </div>



    <div
    data-aos="fade-left"
    data-aos-offset="100"
    data-aos-duration="1000"
    data-aos-once="false"
    className="services-fooder">{/* services-fooder */}
        <p className='fooder-titles'>Services</p>
       <ul className='service-ultag'>
        <li>Computer Scrap</li>
        <li>Industrial Scrap</li>
        <li>Metal Scrap</li>
        <li>Battery Scrap</li>
        <li>E-waste Recycling</li>
       </ul>
    </div>

    <div
    data-aos="fade-left"
    data-aos-offset="100"
    data-aos-duration="1000"
    data-aos-once="false" 
    className="logo-description">{/* logo-description */}
       <img src={logo} className='fooder-logo' />
       <p>Scrap Bazar local scrap buyer in Ahmedabad. We collect all kind of Pasti & metals scrap  like Old News paper, magazine, schoolbooks, waste Iron scrap, aluminum and etc. from both commercial and residential public with market price.</p>
            <div className="insta-fac-whats">
              
           
            <Link to={"https://www.instagram.com/bestscrapscbe?igsh=am5oODhidzVzaWs0"}> <FaInstagram className='i-f-w-icon' /></Link>
            <Link to={"https://www.facebook.com/people/Best-Scrap/pfbid02m9xx7q6PadB5DPtmXzJhvmY4v8tp5sbJgnb5GdnkwPHTJgFz6GLrg9UB5PYZ4Wwql/?mibextid=qi2Omg"}> <AiOutlineFacebook className='i-f-w-icon' /></Link>
            <Link to={'https://wa.me/+918681991212'}><IoLogoWhatsapp  className='i-f-w-icon'/></Link>
           
            </div>
    </div>





</div>
</div>
  )
}
export default Fooderpage;