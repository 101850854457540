import React from 'react';
import "../carousel/Carousel.css"
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import imgone from "../assert/background-one.jpg"
import imgtwo from "../assert/background-two.jpg"
import imgthree from "../assert/background-five.jpg"
import { Link } from 'react-router-dom';
import imgfour from '../assert/background-four.webp'
 

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat", 
  backgroundAttachment:" fixed",   
  height: '680px'
}
const slideImages = [
  {
    url: imgone,
      type:"METAL SCRAPS BUYERS",
    from:"Scaps Buyers in Coimbatore",
    caption: 'Slide 1'
  },
  {
    url:imgtwo ,
    type:"ELECTRONIC SCRAPS BUYERS",
    from:"Scaps Buyers in Coimbatore",
    caption: 'Slide 2'
  },
  {
    url:imgthree  ,
    type:"BEST SCRAPS SOLUTIONS", 
    from:"Scaps Buyers in Coimbatore",
    caption: 'Slide 3'
  },
];

const Slideshow = () => {
    return (
      <div className="slide-container">
        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>

                 <div style={{ ...divStyle,  backgroundImage: `  linear-gradient(rgba(97, 96, 96, 0.234), rgba(214, 216, 214, 0.196)),   url(${slideImage.url})` }}>

                    <div className="carsoul-con">
                                <h1>{slideImage.type}</h1>
                                <h3>{slideImage.from}</h3>

                                <div className="reqvest-btns-car">
                                  <Link to="/reqvestpages"><button>Request for pickup</button></Link>
                                    
                                </div>
                    </div>





                </div>




            </div>
          ))} 
        </Slide>
      </div>
    )
}
  
export default Slideshow;
/* background: linear-gradient(to,top,white,white),
url(); */