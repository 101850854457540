import React, { useEffect, useState,useRef } from 'react'
import "./Experience.css"
import funfact from '../assert/fun-fact.png'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();


export const Experience = () => {
const [value, setValue] = useState(0);


 const timeoutID = useRef(null);
 useEffect(() => {
   
   timeoutID.current = setTimeout(() => {
     if (value >69) {
    
       clearTimeout(timeoutID.current);
       timeoutID.current = null;
       return;
     }
     setValue((value) => value + 1);
   }, 100);

   return () => clearTimeout(timeoutID.current);
 }, [value]);
 


 const [valuetwo, setValuetwo] = useState(0);
 const timeouttwoID = useRef(null);
 useEffect(() => {
   
  timeouttwoID.current = setTimeout(() => {
     if (valuetwo >59) {
    
       clearTimeout(timeouttwoID.current);
       timeouttwoID.current = null;
       return;
     }
     setValuetwo((valuetwo) => valuetwo + 1);
   }, 100);

   return () => clearTimeout(timeouttwoID.current);
 }, [valuetwo]);
 

 const [valuethree, setValuethree] = useState(0);
 const timeoutthreeID = useRef(null);
 useEffect(() => {
   
  timeoutthreeID.current = setTimeout(() => {
     if (valuethree >9) {
    
       clearTimeout(timeoutthreeID.current);
       timeoutthreeID.current = null;
       return;
     }
     setValuethree((valuethree) => valuethree + 1);
   }, 100);

   return () => clearTimeout(timeoutthreeID.current);
 }, [valuethree]);
 
















  return (
<div className="main-experienc">

<div className="experience-total">
    
       <div 
       data-aos="fade-right"
       data-aos-offset="300"
       data-aos-duration="1000"
       data-aos-once="false"
       className="ladey-img">
          <img src={funfact} alt="" />
        </div>
    <div className="exp-detials">

        
        <div
         data-aos="fade-right"
         data-aos-offset="200"
         data-aos-duration="1000"
         data-aos-once="false"
         className="exp-num">
            <h1>{value}<sup>+</sup></h1>{/* 75 */}
            <p>Tonnes of E-Waste Collected</p>
        </div>


        <div
         data-aos="fade-right"
         data-aos-offset="200"
         data-aos-duration="1000"
         data-aos-once="false"
        className="exp-num">
            <h1>{valuetwo}<sup>+</sup></h1>{/* 60 */}
            <p>Tonnes of E-Waste Recycled</p>
        </div>


        <div
         data-aos="fade-left"
         data-aos-offset="200"
       
        className="exp-num">
            <h1>{valuethree}<sup>+</sup></h1>{/* 10 */}
            <p>Years Of Experience</p>
        </div>


    </div>
</div>
</div>
  )
}
export default Experience;